import React , { Component } from 'react'
import { Button, Form, Icon, Grid, Header, Image, Message, Segment, Transition, Loader, Modal } from 'semantic-ui-react'


import logo from '../img/budgetind_logo.png'
import logo_ccip from '../img/ccip_logo.png'


import PubSub from 'pubsub-js';

var request = require("request");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { visible:false ,visibleSenha:'none',visiblemsg:'none', usuario:'', senha:'',activeLogin:false, open: false,
                   email: '', displayMessage: 'none', displayContent: '', displayColor: '', loadingbutton: false
                 }
  }

  componentDidMount(){  
    
    var myVar = setInterval(function(){
      this.setState({visible:true});
      stopColor()
    }.bind(this), 1000);
    
    function stopColor() {
        clearInterval(myVar);
    }
  }
  
  submeteAutenticacao(){
    
    this.setState({activeLogin:true});
    
     var options = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/login',
        headers: {              
        'content-type': 'application/json'
        },
        body: {"usuario":this.state.usuario,"senha":this.state.senha},
        json: true
      };
     
    request(options, function (error, response, body) {
      
      if (error){
        console.log(error)
        this.setState({activeLogin:false});
      }else{
        this.setState({activeLogin:false});
        if(body.mensagem === "autenticacao invalida"){
          this.setState({visibleSenha:''})  
        }else if(body.mensagem === "autenticacao valida"){
          localStorage.setItem('token', body.token)
          localStorage.setItem('keyUsuario', body.usuario.key)
          this.setState({visibleSenha:'none'})  
          PubSub.publish('autenticacao', { validado:true, usuario:body.usuario });
        }
      }            
    }.bind(this));

    
  }
    
 handleDismiss(){
    this.setState({ visiblemsg: 'none' })
  }
  
  handleDismissOn(){
    this.setState({ visiblemsg: '' })
  }

  
  alteraUsuario(e){    
    this.setState({usuario:e.target.value})
  }
  alteraSenha(e){    
    this.setState({senha:e.target.value})
  }
  
  
  open(e, item){ this.setState({ open: true })};
  close(e, item){ this.setState({ open: false })};
  
  handleChange(event){
    this.setState({email: event.target.value, displayMessage: 'none'});
  }
  
  recuperarSenha(){
    
        this.setState({displayMessage: 'none', displayColor: '', displayContent: '', loadingbutton: true});
        
        if (this.state.email.length > 3) {
          var options = {
            method: 'GET',
            url: 'https://inteligenciaindustrial.com/budgetind/recuperarsenha/email/' + this.state.email,
            headers: {
              'content-type': 'application/json'
            },
            json: true
          };
          
          request(options, function(error, response, body) {
            this.setState({
              displayMessage: '', displayContent: body.mensagem, displayColor: body.color, loadingbutton: false
            });
          }.bind(this));
        } else {
          this.setState({displayMessage: '', displayContent: 'Preencho o campo E-mail', displayColor: 'red', loadingbutton: false
          });
        }
  }

  
  render() {
  
  const { visible } = this.state
  return(    
        <Transition.Group animation='fade down' duration='3000'>
          {visible && 
          <div className='login-form'>    
            <style>{`
              body > div,
              body > div > div,
              body > div > div > div.login-form {
                height: 100%;
              }
            `}</style>
            <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>              
                  <Grid.Column style={{ maxWidth: 350}}>
                   <Header as='h2' color='teal' textAlign='center'>
                    <Image src={logo} style={{width:'400px'}} /> 
                  </Header>
                  <Form size='small'>
                    <Loader active={this.state.activeLogin}/>
                    <Segment stacked>
                      <Form.Input fluid icon='user' value={this.state.user} iconPosition='left' placeholder='Usuário' onChange={this.alteraUsuario.bind(this)} />
                      <Form.Input fluid icon='lock' value={this.state.senha} iconPosition='left' placeholder='Senha' type='password'  onChange={this.alteraSenha.bind(this)}/>                      
                      <Button color='blue' fluid size='large' onClick={this.submeteAutenticacao.bind(this)} icon labelPosition='right'>
                        ENTRAR
                        <Icon name='sign in alternate'/>
                      </Button>
                    </Segment>
                  </Form>
                  <Message>
                    Esqueceu usuário ou senha?<a href='#' onClick = {this.open.bind(this)}> Clique aqui!</a>
                  </Message>
                   <Message  color='red' style={{display:this.state.visibleSenha}} >
                    Usuário ou seha inválidos! Tente novamente.
                  </Message>
                  <Header as='p' color='black' style={{fontSize:'10px'}} textAlign='center'>
                    Desenvolvido por 
                    <Image src={logo_ccip} style={{width:'75px'}} /> 
                  </Header>
                  <Header as='p' color='black' style={{fontSize:'8px'}} textAlign='center'>
                    v20191017.001
                  </Header>
                  
                </Grid.Column>
            </Grid>
            <Modal open={this.state.open} onClose={this.close.bind(this)} size='tiny' style = {{ height: 'auto' }}>
                  <Header icon='user circle outline' content='Esqueci minha senha' />
                  <Modal.Content>
                    <Form>
                      <Form.Field>
                        <label>E-mail</label>
                        <input placeholder='Informe seu E-mail' onChange={this.handleChange.bind(this)}/>
                      </Form.Field>
                    </Form>
                    <Message style = {{display: this.state.displayMessage}} color= {this.state.displayColor} closeIcon>
                      <Message.Header>{this.state.displayContent}</Message.Header>
                    </Message>
                </Modal.Content>
                <Modal.Actions>
                  <Button color='blue' onClick={this.recuperarSenha.bind(this)} loading = {this.state.loadingbutton}>
                    <Icon name='send' /> Enviar
                  </Button>
                </Modal.Actions>
              </Modal>
          </div>}
      </Transition.Group>    
  )
}
  }

export default Login
