import React, { Component } from 'react';

import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PubSub from 'pubsub-js';


import { Table, Header, Container, Segment, Icon, Modal, Button, Input, Menu, Dimmer, Loader, Form, Dropdown, Grid, Divider, Card } from 'semantic-ui-react';
import logo from './logo.svg';
import './App.css';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'amcharts3/amcharts/amcharts';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/themes/light';
import AmCharts from "@amcharts/amcharts3-react";

import logo_ from './img/budgetind_logo.png'

var request = require("request");

const formatDecimal = require('format-decimal');
var opts  = {decimal   : ',', precision : 2,thousands : '.'}
var opts2 = {decimal   : ',', precision : 0,thousands : '.'}

class App extends Component {
   constructor(props){
    super(props);
      this.state = {
        loaderGrafico: false,
        loaderPesquisar: false,
        modalHeader:"",
        open:false,
        openFiltros: true,
        dataProvider: [],
        filial:[],
        orcamento:[],
        ano: [ { key: '2019', value: '2019', text: '2019' },{ key: '2018', value: '2018', text: '2018' }],
        mes: [ { key: 'JAN', value: 'JAN', text: 'JAN' }, 
               { key: 'FEV', value: 'FEV', text: 'FEV' }, 
               { key: 'MAR', value: 'MAR', text: 'MAR' }, 
               { key: 'ABR', value: 'ABR', text: 'ABR' }, 
               { key: 'MAI', value: 'MAI', text: 'MAI' }, 
               { key: 'JUN', value: 'JUN', text: 'JUN' }, 
               { key: 'JUL', value: 'JUL', text: 'JUL' }, 
               { key: 'AGO', value: 'AGO', text: 'AGO' }, 
               { key: 'SET', value: 'SET', text: 'SET' }, 
               { key: 'OUT', value: 'OUT', text: 'OUT' }, 
               { key: 'NOV', value: 'NOV', text: 'NOV' }, 
               { key: 'DEZ', value: 'DEZ', text: 'DEZ' }],
        geral:[{VALOR_ORCADO:0,VALOR_REALIZADO:0,VARIACAO_VALOR_MONETARIO:0,VARIACAO_PERCENTUAL:0}],
        area:[],
        mensal: [],
        agrupamento:[],
        centro_de_custo:[],
        conta_contabil:[],
        data_area:[],
        data_agrupamento:[],
        data_centro_de_custo:[],
        data_conta_contabil:[],
        data_area_colunas : [
            { title: "ÁREA", field: "DESC_AREA", align: "center" },
            { title: "ORÇADO", field: "VALOR_ORCADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
            { title: "REAL.", field: "VALOR_REALIZADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
            { title: "VAR. $", field: "VARIACAO_VALOR_MONETARIO", align: "center", formatter:function(cell, formatterParamns){
                var value = cell.getValue();
                if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                }else{
                  return 0
                }
              } 
            },
            { title: "VAR. %", field: "VARIACAO_PERCENTUAL", align: "center", formatter:function(cell, formatterParams){
                var value = cell.getValue();
                if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + value + "</span>";
                }else{
                  return 0
                }
              } 
            }

        ],
        data_agrupamento_colunas: [
          { title: "AGRUPAMENTO", field: "AGRUPAMENTO", align: "center", headerFilter:"input", headerFilterPlaceholder:" " },
          { title: "ORÇADO", field: "VALOR_ORCADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "REAL", field: "VALOR_REALIZADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "VAR. $", field: "VARIACAO_VALOR_MONETARIO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                }else{
                  return 0
                }
            } 
          },
          { title: "VAR. %", field: "VARIACAO_PERCENTUAL", align: "center", formatter:function(cell, formatterParams){
              var value = cell.getValue();
              if (value > 0) {
                return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
              } else if(value < 0) {
                return "<span style='color:green; font-weight:bold;'>" + value + "</span>";
              }else{
                return 0
              }
            } 
          },
        ],
        data_centro_de_custo_colunas: [
          { title: "CENTRO DE CUSTO", field: "DESC_CENTRO_DE_CUSTO", align: "center", headerFilter:"input", headerFilterPlaceholder:" " },
          { title: "ORÇADO", field: "VALOR_ORCADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "REAL", field: "VALOR_REALIZADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "VAR. $", field: "VARIACAO_VALOR_MONETARIO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                }else{
                  return 0
                }
            } },
          { title: "VAR. %", field: "VARIACAO_PERCENTUAL", align: "center", formatter:function(cell, formatterParams){
              var value = cell.getValue();
              if (value > 0) {
                return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
              } else if(value < 0) {
                return "<span style='color:green; font-weight:bold;'>" + value + "</span>";
              }else{
                return 0
              }
            }
          }
        ],
        data_conta_contabil_colunas: [
          { title: "CONTA CONTÁBIL", field: "DESC_CONTA_CONTABIL", align: "center", headerFilter:"input", headerFilterPlaceholder:" " },
          { title: "ORÇADO", field: "VALOR_ORCADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "REAL", field: "VALOR_REALIZADO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              return value !== null && value !== undefined ? formatDecimal(parseInt(value), opts) : 0
            } },
          { title: "VAR. $", field: "VARIACAO_VALOR_MONETARIO", align: "center", formatter:function(cell, formatterParamns){
              var value = cell.getValue();
              if (value > 0) {
                  return "<span style='color:red; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                } else if(value < 0) {
                  return "<span style='color:green; font-weight:bold;'>" + formatDecimal(parseInt(value), opts) + "</span>";
                }else{
                  return 0
                }
            } },
          { title: "VAR. %", field: "VARIACAO_PERCENTUAL", align: "center", formatter:function(cell, formatterParams){
              var value = cell.getValue();
              if (value > 0) {
                return "<span style='color:red; font-weight:bold;'>" + value + "</span>";
              } else if(value < 0) {
                return "<span style='color:green; font-weight:bold;'>" + value + "</span>";
              }else{
                return 0
              }
            } 
          },
        ],
      }
  } 

  componentDidMount(){
    let perfilUsuario = this.props.usuario.perfil;
    console.log(perfilUsuario)
    this.setState({
      loaderPesquisar: true
    });
    let Filial = [], Area = [], Agrupamento = [], Centro_de_custo = [], Conta_contabil = [], FiltrosPerfil = [];
        
    var options_agrupamento = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/filtro/agrupamento',
     headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
    };
    
    var options_centro_de_custo = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/filtro/centro_de_custo',
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
    var options_conta_contabil = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/filtro/conta_contabil',
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
    var options_filtros_perfil = { method: 'GET', 
        url: 'https://inteligenciaindustrial.com/budgetind/opcoesfiltros/filtros/'+perfilUsuario,
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
    };
    
    request(options_filtros_perfil, function (error, response, body) {
      if(JSON.parse(body).mensagem === undefined) {
        FiltrosPerfil = JSON.parse(body);
      
        request(options_agrupamento, function (error, response, body) {
          Agrupamento = JSON.parse(body);

          request(options_centro_de_custo, function (error, response, body) {
            Centro_de_custo = JSON.parse(body);

            request(options_conta_contabil, function (error, response, body) {
              Conta_contabil = JSON.parse(body);

              FiltrosPerfil.forEach(function (value, index) {
                if (value.coluna_valor_filtro === 'filial') {
                  Filial.push({key:value.valor_filtro,value:value.valor_filtro,text:value.valor_filtro});
                }

                if (value.coluna_valor_filtro === 'area') {
                  Area.push({key:value.valor_filtro,value:value.valor_filtro,text:value.descricao_valor_filtro});
                }
              });

              this.setState({ filial: Filial, area: Area, agrupamento: Agrupamento, centro_de_custo: Centro_de_custo, 
                              conta_contabil: Conta_contabil, loaderPesquisar: false });
            }.bind(this));
          }.bind(this));
        }.bind(this));
      } else {
        NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
        setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
      }
    }.bind(this));
    
    
    
    
    

  }
  
  pesquisar(){
    this.setState({
      loaderPesquisar: true
    });
    
    
    if (this.state.desc_filial_selecionados && this.state.desc_filial_selecionados.length > 0 && 
        this.state.anos_selecionados && this.state.anos_selecionados.length > 0 && 
        this.state.mes_abv_selecionados && this.state.mes_abv_selecionados.length > 0 &&
        this.state.desc_area_selecionados && this.state.desc_area_selecionados.length > 0 && 
        this.state.agrupamento_selecionados && this.state.agrupamento_selecionados.length > 0 &&
        this.state.desc_centro_de_custo_selecionados && this.state.desc_centro_de_custo_selecionados.length > 0 &&
        this.state.desc_conta_contabil_selecionados && this.state.desc_conta_contabil_selecionados.length > 0) {
      var options_geral = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/geral',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        },                      
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
      };

      var options_area = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/area',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        },                      
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
      };

      var options_agrupamento = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/agrupamento',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        }, 
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
      };

      var options_centro_de_custo = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/centro_de_custo',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        }, 
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
      };

      var options_conta_contabil = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/conta_contabil',
        json: {
          "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
          "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
          "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
          "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
          "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
          "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
          "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
          "USUARIO":this.props.usuario.key
        }, 
        headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        },
      };

      let Geral = [], Data_area = [], Data_agrupamento = [], Data_centro_de_custo = [], Data_conta_contabil = [] 

      request(options_geral, function (error, response, body) {
        if(body.mensagem === undefined) {
          
          Geral = body;

          request(options_area, function (error, response, body) {
            Data_area = body;

            request(options_agrupamento, function (error, response, body) {
              Data_agrupamento = body;

              request(options_centro_de_custo, function (error, response, body) {
                Data_centro_de_custo = body;   

                request(options_conta_contabil, function (error, response, body) {
                  Data_conta_contabil = body;

                  this.setState({ geral: Geral, data_area: Data_area, data_agrupamento: Data_agrupamento, data_centro_de_custo: Data_centro_de_custo,
                              data_conta_contabil: Data_conta_contabil, loaderPesquisar: false, openFiltros: false});
                }.bind(this));
              }.bind(this));
            }.bind(this));
          }.bind(this)); 
        } else {
          NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
          setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
        }
      }.bind(this));
    } else {
      NotificationManager.warning("Preencha todos os campos");
      this.setState({ loaderPesquisar: false});
    }     
    
}
    
  
  onRowSelected(event) {
    console.log(event.node.data);
  }
  

  handleChangeAnos = (e, { value }) => {this.setState({ anos_selecionados: value })}
  handleChangeDescFilial = (e, { value }) => {this.setState({ desc_filial_selecionados: value })}
  handleChangeMesAbv = (e, { value }) => {this.setState({ mes_abv_selecionados: value })}
  handleChangeDescArea = (e, { value }) => {this.setState({ desc_area_selecionados: value })}
  handleChangeAgrupamento = (e, { value }) => {this.setState({ agrupamento_selecionados: value })}
  handleChangeDescCentroDeCusto = (e, { value }) => {this.setState({ desc_centro_de_custo_selecionados: value })}
  handleChangeDescContaContabil = (e, { value }) => {this.setState({ desc_conta_contabil_selecionados: value })}

  
  pesquisarGraficoArea(e, row){
    
    this.setState({mensal:[], open: true, loaderGrafico: true});  
    
    
     var options_mensal = { method: 'POST',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/mensal',
      json: {
        "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
        "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
        "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
        "DESC_AREA":row._row.data.DESC_AREA,
        "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
        "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
        "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
        "USUARIO":this.props.usuario.key
      },                      
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
     request(options_mensal, function (error, response, body) {
       if(body.mensagem === undefined) {
         if(body.length > 0) {
           body.forEach(function(x){
             x.VALOR_ORCADO = x.VALOR_ORCADO !== null ? parseInt(x.VALOR_ORCADO) : 0;
             x.VALOR_REALIZADO = x.VALOR_REALIZADO !== null ? parseInt(x.VALOR_REALIZADO) : 0;
             x.VARIACAO_PERCENTUAL = x.VARIACAO_PERCENTUAL !== null ? parseInt(x.VARIACAO_PERCENTUAL) : 0;
             x.VARIACAO_VALOR_MONETARIO = x.VARIACAO_VALOR_MONETARIO !== null ? parseInt(x.VARIACAO_VALOR_MONETARIO) : 0;
           });
         }
        this.setState({mensal:body},()=> this.setState({open: true, loaderGrafico: false},()=>this.setState({modalHeader:" ÁREA "+ row._row.data.DESC_AREA, loaderGrafico: false})));  
       } else {
         this.setState({ loaderGrafico: false });
         NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
         setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
       }
    }.bind(this));
    
  }

  pesquisarGraficoAgrupamento(e, row){
    
    this.setState({open: true, mensal:[], modalHeader:" AGRUPAMENTO " + row._row.data.AGRUPAMENTO, loaderGrafico: true}); 
    
     var options_mensal = { method: 'POST',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/mensal',
      json: {
        "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
        "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
        "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
        "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
        "AGRUPAMENTO":row._row.data.AGRUPAMENTO,
        "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
        "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
        "USUARIO":this.props.usuario.key
      },                      
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
     request(options_mensal, function (error, response, body) {
       if(body.mensagem === undefined) {
          if(body.length > 0) {
           body.forEach(function(x){
             x.VALOR_ORCADO = x.VALOR_ORCADO !== null ? parseInt(x.VALOR_ORCADO) : 0;
             x.VALOR_REALIZADO = x.VALOR_REALIZADO !== null ? parseInt(x.VALOR_REALIZADO) : 0;
             x.VARIACAO_PERCENTUAL = x.VARIACAO_PERCENTUAL !== null ? parseInt(x.VARIACAO_PERCENTUAL) : 0;
             x.VARIACAO_VALOR_MONETARIO = x.VARIACAO_VALOR_MONETARIO !== null ? parseInt(x.VARIACAO_VALOR_MONETARIO) : 0;
           });
         }
        this.setState({mensal:body, loaderGrafico: false});
       } else {
         this.setState({ loaderGrafico: false})
         NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
         setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
       }
    }.bind(this));
    
  }

  pesquisarGraficoCentroDeCusto(e, row){
    
    this.setState({open: true, loaderGrafico: true, mensal:[], modalHeader:" CENTRO DE CUSTO "+row._row.data.DESC_CENTRO_DE_CUSTO});  
    
     var options_mensal = { method: 'POST',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/mensal',
      json: {
        "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
        "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
        "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
        "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
        "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
        "DESC_CENTRO_DE_CUSTO":row._row.data.DESC_CENTRO_DE_CUSTO,
        "DESC_CONTA_CONTABIL":this.state.desc_conta_contabil_selecionados.toString().replace(/,/g,'|'),
        "USUARIO":this.props.usuario.key
      },                      
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
     request(options_mensal, function (error, response, body) {
       if(body.mensagem === undefined) {
          if(body.length > 0) {
           body.forEach(function(x){
             x.VALOR_ORCADO = x.VALOR_ORCADO !== null ? parseInt(x.VALOR_ORCADO) : 0;
             x.VALOR_REALIZADO = x.VALOR_REALIZADO !== null ? parseInt(x.VALOR_REALIZADO) : 0;
             x.VARIACAO_PERCENTUAL = x.VARIACAO_PERCENTUAL !== null ? parseInt(x.VARIACAO_PERCENTUAL) : 0;
             x.VARIACAO_VALOR_MONETARIO = x.VARIACAO_VALOR_MONETARIO !== null ? parseInt(x.VARIACAO_VALOR_MONETARIO) : 0;
           });
         }
        this.setState({mensal:body, loaderGrafico: false});  
       } else {
         this.setState({loaderGrafico: false})
         NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
         setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
       }
    }.bind(this));
    
  }

  pesquisarGraficoContaContabil(e, row){
    
    this.setState({open: true, loaderGrafico: true, mensal:[], modalHeader:" CONTA CONTÁBIL "+row._row.data.DESC_CONTA_CONTABIL});
    
     var options_mensal = { method: 'POST',
      url: 'https://inteligenciaindustrial.com/budgetind/orcamento/data/mensal',
      json: {
        "ANO":this.state.anos_selecionados.toString().replace(/,/g,'|'),
        "DESC_FILIAL":this.state.desc_filial_selecionados.toString().replace(/,/g,'|'),
        "MES_ABV":this.state.mes_abv_selecionados.toString().replace(/,/g,'|'),
        "DESC_AREA":this.state.desc_area_selecionados.toString().replace(/,/g,'|'),
        "AGRUPAMENTO":this.state.agrupamento_selecionados.toString().replace(/,/g,'|'),
        "DESC_CENTRO_DE_CUSTO":this.state.desc_centro_de_custo_selecionados.toString().replace(/,/g,'|'),
        "DESC_CONTA_CONTABIL":row._row.data.DESC_CONTA_CONTABIL,
        "USUARIO":this.props.usuario.key
      },                      
      headers: {
          'content-type': 'application/json',
          'x-access-token': localStorage.getItem("token")
        }, 
    };
    
     request(options_mensal, function (error, response, body) {
       if(body.mensagem === undefined) {
         if(body.length > 0) {
           body.forEach(function(x){
             x.VALOR_ORCADO = x.VALOR_ORCADO !== null ? parseInt(x.VALOR_ORCADO) : 0;
             x.VALOR_REALIZADO = x.VALOR_REALIZADO !== null ? parseInt(x.VALOR_REALIZADO) : 0;
             x.VARIACAO_PERCENTUAL = x.VARIACAO_PERCENTUAL !== null ? parseInt(x.VARIACAO_PERCENTUAL) : 0;
             x.VARIACAO_VALOR_MONETARIO = x.VARIACAO_VALOR_MONETARIO !== null ? parseInt(x.VARIACAO_VALOR_MONETARIO) : 0;
           });
         }
        this.setState({mensal:body, loaderGrafico: false});
       } else {
         this.setState({loaderGrafico: false})
         NotificationManager.error("Token inválido, faça a o login para gerar um novo token. Você será redirecionado para a tela de login em 5 segundos");
         setTimeout(function(){ PubSub.publish('autenticacao', { validado:false }); }, 6000);
       }          
    }.bind(this));
    
  }
    close = () => this.setState({ open: false });

    closeFiltros = () => this.setState({ openFiltros: false });
  
    openModalFiltros = () => this.setState({ openFiltros: true });

  render() {
    const { anos_selecionados , 
           mes_abv_selecionados,
            desc_filial_selecionados,
            desc_area_selecionados,
            agrupamento_selecionados,
            desc_centro_de_custo_selecionados,
            desc_conta_contabil_selecionados,
            open, openFiltros, closeOnEscape, closeOnDimmerClick} = this.state
    
  const config = {
      "numberFormatter": {
        "numberFormat": "#,###.##"
      },
      "type": "serial",
      "theme": "light",
      "marginRight": 40,
      "marginLeft": 40,
      "autoMarginOffset": 20,
      "mouseWheelZoomEnabled": false,
      "valueAxes": [{
        "id": "ax1",
        "axisAlpha": 0,
        "position": "left",
        "ignoreAxisWidth": true,
        "labelsEnabled": false,
        "gridThickness":0
      }],
      "balloon": {
        "borderThickness": 1,
        "shadowAlpha": 0
      },
      "graphs": [{
        "id": "VALOR_REALIZADO",
        "type": "column",
        "balloon":{
          "drop": true,
          "adjustBorderColor": false,
          "color":"#ffffff"
        },
       
        "fillAlphas": 0.9,
			  "fillColors": "#1a69a4",   
        "lineColor": "#1a69a4",   
        "title": "REALIZADO",
        "valueField": "VALOR_REALIZADO",
        "showBalloon": false,
        "labelText" : "[[value]]",
        "labelRotation": -90
      },{
        "id": "VALOR_ORCADO",
        "type": "column",
        "balloon":{
          "drop": true,
          "adjustBorderColor": false,
          "color":"#ffffff"
        },
       
        "fillAlphas": 0.9,
			  "fillColors": "#1a69a4ad",                
        "lineColor": "#1a69a4ad",  
        "title": "ORÇADO",
        "valueField": "VALOR_ORCADO",
        "showBalloon": false,
        "labelText" : "[[value]]",
        "labelRotation": -90
      },
                {
        "id": "VARIACAO_VALOR_MONETARIO",
        "type": "column",
        "balloon":{
          "drop": true,
          "adjustBorderColor": false,
          "color":"#ffffff"
        },
       
        "fillAlphas": 0.9,
			  "fillColors": "#767676c4",                
        "lineColor": "#767676c4",  
        "title": "VARIAÇÃO $",
        "valueField": "VARIACAO_VALOR_MONETARIO",
        "showBalloon": false,
        "labelText" : "[[value]]",
        "hidden":true,
        "labelPosition":"top",
        "labelFunction": function(graphDataItem, graph){
          let a = 0, b = 0;
          if(graphDataItem.values.value !== null){
            a = graphDataItem.values.value
          };
          if (graphDataItem.dataContext.VARIACAO_PERCENTUAL !== null) {
            b = graphDataItem.dataContext.VARIACAO_PERCENTUAL
          }
            var value = formatDecimal(parseInt(a), opts2) + " ("+formatDecimal(parseInt(b), opts2)+"%)";
           // value = formatTextLabel(value);
            return value;
        },
        "labelRotation": -90
      }],
      "categoryField": "MES_ABV",
      "categoryAxis": {
        "parseDates": false,
        "dashLength": 1,
        "minorGridEnabled": true,
        "gridThickness": 0
      },
    	"legend": {
        "enabled": true,
        "useGraphSettings": true
      },
      "dataProvider": this.state.mensal
    };
    
 
    
    let styleCondition = this.state.geral[0].VARIACAO_PERCENTUAL > 0 ? {textAlign:'center', color:'red'} : {textAlign:'center', color:'green'};

    return (
          <div>
            <div style = {{ paddingTop: "5px", paddingBottom: "20px", paddingRight: "15px" }}>
              <Button floated='right' icon labelPosition='right' color='blue'  onClick={this.openModalFiltros.bind(this)}>
                Filtrar
                <Icon name='filter' />
              </Button>
            </div>
            <Modal open={openFiltros} onClose={this.closeFiltros} closeIcon>
              <Header icon='filter' content='FILTROS' />
              <Modal.Content>
                <NotificationContainer/>
                <Form>
              <Form.Group widths='equal'>
                  <Form.Field style={{width:'150px'}}>
                    <label>Filial:</label>
                    <Dropdown placeholder='FILIAL' fluid multiple search selection options={this.state.filial}  value={desc_filial_selecionados} onChange={this.handleChangeDescFilial}/>
                  </Form.Field>
                  <Form.Field style={{width:'150px'}}>
                    <label>Ano:</label>
                    <Dropdown placeholder='ANO' fluid multiple search selection options={this.state.ano} value={anos_selecionados} onChange={this.handleChangeAnos}/> 
                  </Form.Field>
                  <Form.Field style={{width:'150px'}}>
                    <label>Mês:</label>
                    <Dropdown placeholder='MÊS' fluid multiple search selection options={this.state.mes} value={mes_abv_selecionados} onChange={this.handleChangeMesAbv}/>
                  </Form.Field>
                </Form.Group>
                 <Form.Group widths='equal'>
                    <Form.Field>
                       <label>Área:</label>
                       <Dropdown placeholder='AREA' fluid multiple search selection options={this.state.area} value={desc_area_selecionados} onChange={this.handleChangeDescArea}/>
                    </Form.Field>
                    <Form.Field >
                      <label>Agrupamento:</label>
                      <Dropdown placeholder='AGRUPAMENTO' fluid multiple search selection options={this.state.agrupamento} value={agrupamento_selecionados} onChange={this.handleChangeAgrupamento}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field >
                      <label>Centro de Custo:</label>
                      <Dropdown placeholder='CENTRO DE CUSTO' fluid multiple search selection options={this.state.centro_de_custo} value={desc_centro_de_custo_selecionados} onChange={this.handleChangeDescCentroDeCusto}/>
                    </Form.Field>
                      <Form.Field>
                      <label>Conta Contábil:</label>
                      <Dropdown placeholder='CONTA CONTÁBIL' fluid multiple search selection options={this.state.conta_contabil} value={desc_conta_contabil_selecionados} onChange={this.handleChangeDescContaContabil}/>    
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field>
                      <label style={{color:'white'}}>Pesquisar</label>
                       <Button floated='right' loading = { this.state.loaderPesquisar } disabled = { this.state.loaderPesquisar } icon labelPosition='right' onClick={this.pesquisar.bind(this)} color='blue'>
                       Pesquisar
                      <Icon name='search' />
                      </Button>
                    </Form.Field>
                </Form.Group>
             </Form>
              </Modal.Content>             
            </Modal>
      <Segment basic style={{marginBottom:'0px',paddingBottom:'0px'}}>
     

           </Segment >
           <Segment basic style={{marginTop:'0px'}}>
             <Grid columns={4} > 
                 <Grid.Row style={{paddingTop: '0px'}}>               
                  <Grid.Column >
                    <Card style={{width: "100%"}}>
                      <Card.Content header='Orçado' />
                      <Card.Content >
                       <Header as='h1' style={{textAlign:'center', color:'blue'}}>
                          R$ {this.state.geral[0].VALOR_ORCADO !== null ? formatDecimal(parseInt(this.state.geral[0].VALOR_ORCADO), opts) : '0,00'}
                       </Header>
                     </Card.Content>
                   </Card>
                  </Grid.Column>
                   <Grid.Column >
                     <Card style={{width: "100%"}}>
                      <Card.Content header='Realizado' />
                      <Card.Content >
                       <Header as='h1' style={{textAlign:'center', color:'blue'}}>
                          R$ {this.state.geral[0].VALOR_REALIZADO !== null ? formatDecimal(parseInt(this.state.geral[0].VALOR_REALIZADO), opts) : '0,00'}
                       </Header>
                     </Card.Content>
                   </Card>
                  </Grid.Column>
                   <Grid.Column >
                     <Card style={{width: "100%"}}>
                      <Card.Content header='Variação $' />
                      <Card.Content >
                       <Header as='h1' style={styleCondition}>
                          R$ {this.state.geral[0].VARIACAO_VALOR_MONETARIO !== null ? formatDecimal(parseInt(this.state.geral[0].VARIACAO_VALOR_MONETARIO), opts) : '0,00'}
                       </Header>
                     </Card.Content>
                   </Card>
                  </Grid.Column>
                   <Grid.Column >
                    <Card style={{width: "100%"}}>
                      <Card.Content header='Variação %' />
                      <Card.Content >
                       <Header as='h1' style={styleCondition}>
                          {this.state.geral[0].VARIACAO_PERCENTUAL !== null ? formatDecimal(parseFloat(this.state.geral[0].VARIACAO_PERCENTUAL), opts) : '0,00'} %
                       </Header>
                     </Card.Content>
                   </Card>
                  </Grid.Column>
              </Grid.Row> 
              
            </Grid>
          </Segment >

           <Segment basic style={{marginTop:'0px'}}>

            <Grid columns={2} >
              <Grid.Row style={{height: '300px', marginBottom:'80px'}} >               
                <Grid.Column style={{height: '300px', paddingBottom:'10px'}}>
                   <Header as='h5' attached='top'>
                    Orçamento por Área
                  </Header>
                    <Segment attached style={{height: '300px', padding: '0px'}}>
                  <ReactTabulator data={this.state.data_area} style={{ height: '-webkit-fill-available', margin:'0px', fontSize:'9px','border':'0px'}}
                                  options={this.state.options} className={'very compact celled'}
                                  columns={this.state.data_area_colunas}
                                  rowDblClick={this.pesquisarGraficoArea.bind(this)}
                                  tooltips={true}
                                  layout={"fitColumns"}/>     
              </Segment >
                </Grid.Column>
                <Grid.Column style={{height: '300px', paddingBottom:'10px'}}>
                    <Header as='h5' attached='top'>
                    Orçamento por Agrupamento
                  </Header>
                    <Segment attached style={{height: '300px', padding: '0px'}}>
                     <ReactTabulator data={this.state.data_agrupamento} style={{ height: '-webkit-fill-available', margin:'0px', fontSize:'9px','border':'0px'}}
                                  options={this.state.options} className={'very compact celled'}
                                  columns={this.state.data_agrupamento_colunas}
                                  rowDblClick={this.pesquisarGraficoAgrupamento.bind(this)}
                                  tooltips={true}
                                  layout={"fitColumns"}/> 
</Segment >

                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{height: '300px', marginBottom:'50px'}}>
               
                <Grid.Column style={{height: '300px', paddingBottom:'10px'}}>
                    <Header as='h5' attached='top'>
                    Orçamento por Centro de Custo
                  </Header>
                    <Segment attached style={{height: '300px', padding: '0px'}}>
                    <ReactTabulator data={this.state.data_centro_de_custo} style={{ height: '-webkit-fill-available',margin:'0px', fontSize:'9px','border':'0px'}}
                                  options={this.state.options} className={'very compact celled'}
                                  columns={this.state.data_centro_de_custo_colunas}
                                  rowDblClick={this.pesquisarGraficoCentroDeCusto.bind(this)}
                                  tooltips={true}
                                  layout={"fitColumns"}/>  
</Segment >
                </Grid.Column>
                <Grid.Column style={{height: '300px', paddingBottom:'10px'}}>
                     <Header as='h5' attached='top'>
                    Orçamento por Conta Contábil
                  </Header>
                    <Segment attached style={{height: '300px', padding: '0px'}}>
                      <ReactTabulator  data={this.state.data_conta_contabil} style={{ height: '-webkit-fill-available', margin:'0px', fontSize:'9px','border':'0px'}}
                                  options={this.state.options} className={'very compact celled'}
                                  columns={this.state.data_conta_contabil_colunas}
                                  rowDblClick={this.pesquisarGraficoContaContabil.bind(this)}
                                  tooltips={true}
                                  layout={"fitColumns"}/>
                    </Segment >
                </Grid.Column>
              </Grid.Row>
            </Grid>
            </Segment >
            <Modal
              open={open}              
              style={{'width':'90%'}}
              onClose={this.close}
              closeIcon>
              <Header icon='chart bar' content={'ANÁLISE MENSAL ' + this.state.modalHeader} />
              <Modal.Content>
                <NotificationContainer/>
                { this.state.loaderGrafico === true && <Dimmer active inverted>
                  <Loader inverted content='Carregando dados' />
                </Dimmer>}
                <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
              </Modal.Content>             
            </Modal>
           </div>
    );
  }
}

function currencyFormatter(params) {
  return  formatNumber(params.value);
}
function formatNumber(number) {
  return number !== null ? formatDecimal(parseInt(number), opts) : 0
}

export default App;
