import React, { Component } from 'react';

import "react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.min.css";
//import * as moment from 'moment';
//import 'moment/locale/pt-br';

import './App.css';

import PubSub from 'pubsub-js';
import { Grid, Sidebar, Menu, Icon, Segment } from 'semantic-ui-react';


import Login from './autenticacao/login';
import Principal from './App_.js';
import AlterarSenha from './componentes/alterasenha/index.js'
import logo_ from './img/budgetind_logo.png'


//moment.locale('pt-BR');
//console.log(moment.locale()); // pt-BR


class App extends Component {
  constructor(props){
    super(props);
    this.state = {component:'login',usuario:'', permissao:'', exibeMenuSuperior: false, componenteExibir: ''}
    this.exibeMenuSuperior = this.exibeMenuSuperior.bind(this);
    this.sair = this.sair.bind(this);
    this.exibeComponenteAlterarSenha = this.exibeComponenteAlterarSenha.bind(this);
  }
  
  componentDidMount(){
    PubSub.subscribe('componente', function (topic, data) {
      this.setState({
        componenteExibir: data.componente
      })
    }.bind(this));
    
    PubSub.subscribe('autenticacao', function (topic, data) {                
      if(data.validado === true){
        this.setState({component:'principal', usuario:data.usuario, permissao:data.permissao})
      }else if(data.validado === false){
        this.setState({component:'login'})
      }
    }.bind(this));        
  }
  
  exibeMenuSuperior() {
    const { exibeMenuSuperior } = this.state;
    this.setState({
      exibeMenuSuperior: exibeMenuSuperior === true ? false : true
    });
  }
  
  sair() {
    this.setState({
      componenteExibir: '', component: 'login'
    })
  }
  
  exibeComponenteAlterarSenha() {
    this.setState({
      component : 'principal',  componenteExibir: 'alterarSenha'
    })
  }

  
  render() {
    const { exibeMenuSuperior, componenteExibir } = this.state;
    let objetoComponente;
    
    
    switch(componenteExibir) {
      case 'alterarSenha':
        objetoComponente = <AlterarSenha />
        break;
      default:
        objetoComponente = <Principal usuario={this.state.usuario} permissao={this.state.permissao} />
    }

    
    
    if (this.state.component === 'login') {
      return (<Login/>);
    } else if (this.state.component === 'principal') {
        {/*return (<Principal usuario={this.state.usuario} permissao={this.state.permissao} />);*/}
        return(
          <div>
          <Segment  basic attached='top' style={{padding: '0px'}}>
            <Grid>
              <Grid.Column width={2}>
                <Icon name = 'sidebar' link style={{ marginTop:'20px', marginLeft:'10px' }} disabled = {exibeMenuSuperior} 
                      onClick = { this.exibeMenuSuperior }/>
              </Grid.Column >
              <Grid.Column width={12} style={{textAlign: 'center'}}>
                <img src={logo_} style={{width:'200px'}}/>
              </Grid.Column>
              <Grid.Column width={2}></Grid.Column>
            </Grid>
            </Segment>
          <Sidebar.Pushable style = {{ padding: '0px'}}>
              <Sidebar as = { Menu } animation = 'overlay' inverted onHide = { this.exibeMenuSuperior } 
                       direction = 'top' visible = { exibeMenuSuperior } width = 'thin'>
                <Menu.Item as = 'a' onClick = { this.exibeComponenteAlterarSenha }>
                  <Icon name = 'edit outline' />
                    Alterar Senha
                </Menu.Item>
                <Menu.Item as = 'a' onClick = { this.sair }>
                  <Icon name = 'log out' />
                    Sair
                </Menu.Item>
              </Sidebar>
              <Sidebar.Pusher>
                <Segment basic>
                  <div>{objetoComponente}</div>
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </div>
        )
    }    
  }
}

export default App;
